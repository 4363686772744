import React from "react"
import Layout from "../components/layout"

const MyJourney = () => {
  return (
    <Layout>
      <div className="min-h-screen">
        Here are my <span className="text-9xl text-red-600">My Journey!</span>
      </div>
    </Layout>
  )
}

export default MyJourney
